<mat-card class="add-caregroup-card mat-elevation-z">
  <mat-card-header>
    <mat-card-title *ngIf="data.title">{{data.title}}</mat-card-title>
    <mat-card-title *ngIf="!data.title">Weet je het zeker?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <p *ngIf="!data.message">{{data}}</p>

    <mat-card-actions>
      <button mat-button class="button-Kill btn addCard-button" (click)="close()">{{data.cancelText}}</button>
      <button
        [disabled]="data.warningText"
        mat-raised-button
        class="button-Add btn addCard-button"
        style="background-color: {{color}}"
        [color]="data.confirmText === 'Verwijderen' ? 'warn' : 'primary'"
        (click)="confirm()"
      >
        {{data.confirmText}}
      </button>
    </mat-card-actions>

    <span *ngIf="data.warningText" class="warning-message">{{data.warningText}}</span>
  </mat-card-content>
</mat-card>
