<mat-card>
  <mat-card-header>
    <mat-card-title>Document uploaden</mat-card-title>
    <button mat-button mat-dialog-close class="close-button" align="end">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="uploadFormGroup" (ngSubmit)="save()">
      <div class="dialog-content-container uploadDocumentDialog">
        <mat-file-upload
          [labelText]="prettyExtensions"
          [selectButtonText]="'Kies bestand'"
          [uploadButtonText]="'Uploaden'"
          [allowMultipleFiles]="false"
          [showUploadButton]="false"
          (selectedFilesChanged)="selectedFilesChanged($event)"
          [acceptedTypes]="data.extensions"
        >
        </mat-file-upload>

        <mat-form-field *ngIf="data.dropdown && data.dropdown.length > 0">
          <mat-label>Type bestand</mat-label>
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let type of data.dropdown" [value]="type.value">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button #hiddenSaveButton [hidden]="true"></button>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button mat-button (click)="close()">Terug</button>
    <app-finish-form-button
      [text]="'Opslaan'"
      [busyVariable]=""
      [hiddenButton]="hiddenSaveButton"
    ></app-finish-form-button>
  </mat-card-actions>
</mat-card>
