import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-finish-form-button',
  templateUrl: './finish-form-button.component.html',
  styleUrls: ['./finish-form-button.component.scss']
})
export class FinishFormButtonComponent implements OnInit {
  @Input() text = 'Opslaan';
  @Input() busyVariable: boolean;
  @Input() hiddenButton: HTMLButtonElement;
  @Output() cancelEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  cancelForm(): void {
    this.cancelEvent.emit(true);
  }

}
