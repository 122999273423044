import { MatTableDataSource } from '@angular/material/table';
import {ClientStudyTrack, StudyComponent, StudyComponentType, StudyModuleType} from "./index";

export class PaginatedResult<T> {
  items: T[];
  meta: IPaginationMeta;
  links?: IPaginationLinks;
}

export interface IPaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface IPaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

export interface IOverviewData<T> {
  title: string;
  id: string;
  dataSource: MatTableDataSource<T>;
}

export class DayPartDate {
  date: Date;
  id: PartOfDay;
  name: string;
}

export enum PartOfDay {
  Morning = "morning",
  Afternoon = "afternoon"
}

// -- Constants -- //
export const DefaultPaginationValues = {
  PageSizeOptions: [5, 10, 15, 25, 50],
  PageSize: 5
}

export const DefaultPagination = {
  itemCount: 0,
  totalItems: 0,
  itemsPerPage: DefaultPaginationValues.PageSize,
  totalPages: 1,
  currentPage: 1
} as IPaginationMeta;

export class ButtonData {
  icon: string;
  text: string;
  onClick: Function;
  disabledIf?: (() => boolean) = () => false;

  constructor(args?: Partial<ButtonData>) {
    Object.assign(this, args);
  }
}

// -- Backend errors -- //
export class HttpErrorMessage {
  field: string;
  error: string;
}

export class HttpError {
  statusCode: number;
  message: HttpErrorMessage[] | string[] = [];
  error: string;
}

export class DialogData {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  warningText?: string;
}

export class ConfirmDialogData extends DialogData {
  deletable?: boolean;
}

export interface BannerData {
  message: string;
  buttons: Array<{
    text: string;
    type: string;
    color?: string;
    onClick: Function;
  }>;
}

export enum GradingType {
  Dropdown = 'dropdown',
  Number = 'number'
}

export class GradingDialogData extends DialogData {
  gradingType: GradingType;
  allowedGradings?: DropdownData[];
  remarks?: string;
}

export class SendSMSDialogData extends DialogData {
  recipient?: string;
  client?: any;
  recipients?: string[];
  clients?: any[];
}

export interface DropdownData {
  name: string;
  value: any;
  role?: string;
}

export class StudyResultDialogData extends DialogData {
  component: StudyComponent;
  allowedGradings: DropdownData[];
  client: any; // TODO: Can't use Client here, should fix that.
  studyTrack?: ClientStudyTrack;
  componentType?: StudyComponentType;
  data: { // TODO: Create a class for this.
    id: number;
    type: string,
    resultType: string,
    clientStudyTrack: any,
    date: Date,
    result: number,
    remarks: string,
    studyProfileComponent?: any,
    component?: any,
  };
}

export class ProcessGo {
  id: number;
  introduction: GoIntroduction;
  rating: GoRating;
  comment: GoComment;
}

export class GoIntroduction {
  scheduledFor: GoDate;
  notes: string;
}

export class GoRating {
  sufficient: number;
  particularities?: string;
  notes?: string;
}

export class GoComment {
  comment: string;
}

export class GoDate {
  start: string;
  end: string;
  title: string;
  description?: string;
  type?: string;
}

// -- Helper -- //
function autoImplement<T>(): new () => T {
  return class { } as any;
}

export enum SbbLabels {
  Label_A = 'A',
  Label_B = 'B',
  Label_C = 'C',
  Label_D = 'D',
  Label_E = 'E',
  Label_L = 'L',
}

// FIXME: this is already retrieved from the backend, why is it specified here? Seems to be used for frontend display of "Voortgang" names.
export enum StudyModuleTypes {
  Generic = 'generic',
  TestModule = 'tests',
  ExamModule = 'exams',
  InternshipModule = 'internship',
  CourseModule = 'courses',
  ChoiceCourseModule = 'choiceCourses',
  SkillModule = 'skills',
  VirtualSkillModule = 'virtualSkills',
}

export enum DateTimeNotations {
  DefaultDateNotation = 'DD-MM-YYYY',
  DefaultTimeNotation = 'HH:mm',
  DefaultCombinedNotation = 'DD-MM-YYYY | HH:mm'
}

// Definition, key and sortHeader are usually the same, maybe we can just use one key.
export interface TableColumns {
  definition: string;
  headerText: string;
  key: string;
  sortHeader?: string;
  actions?: { headerActions?: string[], rowActions?: string[], noMenuActions?: string[] };
  classes?: string | ((column, value) => string); // Some columns/rows need specific styling (see qualifiedUntil), you can pass a string of a function that returns a string.
  parse?: { array?: any, isDate?: boolean, dateFormat?: string };
}

// export interface QuizQuestion {
//   question: string;
//   pointsToBeGained: number[];
//   type: string;
//   explanationRequired: boolean;
// }
//
// export class QuizQuestion implements QuizQuestion {
//   constructor() {
//     this.pointsToBeGained = [3, 2, 0];
//     this.type = 'multipleChoice';
//   }
// }
//
// export interface QuizCase {
//   title: string;
//   description: string;
//   image: DocUpload;
//   videoUrl?: string;
//   questions: QuizQuestion[];
// }
//
// export class QuizCase implements QuizCase {
//   title: string;
//   description: string;
//   imageUrl?: string;
//   videoUrl?: string;
//   questions: QuizQuestion[];
//
//   constructor() {
//     this.title = 'Casus';
//     this.description = 'Cas valt van een berg met 38 km/h. Terwijl hij valt, komen er verschillende cactussen op zijn pad. Hoeveel koffie moet Cas drinken om maandagochtend om 10:00 bij de stand up te zijn?';
//     this.imageUrl = '';
//     this.videoUrl = '';
//     this.questions = [];
//   }
// }

// -- Widgets -- //

export class WidgetItem {
  icon?: string;
  type: string;
  value: string | number;
  expandable?: {
    active: boolean,
    type: string
  };
  onClick?: () => void;
  disabledIf?: (() => boolean) = () => false;

  constructor(args?: Partial<ButtonData>) {
    Object.assign(this, args);
  }
}

export class Elearning {
  clientCohort: string;
  sessionId: number;
  clientNumber: string;
  clientName: string;
  study: string;
  quizName: string;
  moduleName: string;
  start: Date;
  end: Date;
  totalPointsReceived: number;
  totalPoints: number;
  minimumPointsToPass: number;
  maxQuizAttempt: number;
}

export class ElearningAttempts {
  clientQuiz_id: number;
  clientQuiz_title: string;
  attempts: number;
  countQuizOpened: number;
  maxAttempts: number;
}

export const StudyCohorts = [
  {name: '2020 - 2021', id: '2020 - 2021'},
  {name: '2021 - 2022', id: '2021 - 2022'},
  {name: '2022 - 2023', id: '2022 - 2023'},
  {name: '2023 - 2024', id: '2023 - 2024'},
];
